import React from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import { FaPhoneVolume } from "react-icons/fa6";
import { MdLocationOn, MdOutlineEmail } from "react-icons/md";
import { BiLogoFacebookCircle } from "react-icons/bi";
import Imgxing from '../../assets/xingImg.png'
import Imglinkidin from '../../assets/Linkdin.png'
import Imgyoutube from '../../assets/youtube.png'
import Imgkaggle from '../../assets/kaggleImg.png'
import Imgfacebook from '../../assets/Facebook.png'
import { Link } from 'react-router-dom';
import './HfFooter.css'
import { useTranslation } from 'react-i18next';



import accessibility from '../../assets/policy/Accessibility-Policy-HFC.pdf';

const HfFooter = () => {
  const { t } = useTranslation();

  return (
    <footer className='hfFooter-wrapper' id='contact-us-section'>
      <Container className='hfFooter-container'>
        <Row className="justify-content-center " style={{ marginLeft: 'auto' }}>
          <Col sm={12} lg={4} >
            <div className='footer-column-div'>
              <b>{(t('How to reach us'))}</b>
              <ul>
                <li><a href="tel:+5625801560" className="footer-link"><FaPhoneVolume /> +5625801560</a></li>
                <li><a href="mailto:cls@hansa-flex.com" className="footer-link"><MdOutlineEmail /> cls@hansa-flex.com</a></li>
                <li><a href="https://www.google.com/maps/place/HANSA-FLEX+CHILE+S.A./@-33.3758348,-70.7570585,17z/data=!3m1!4b1!4m6!3m5!1s0x9662c6ebe3e317ad:0xa54d8f5bf7790b7e!8m2!3d-33.3758348!4d-70.7570585!16s%2Fg%2F11b7q5rjww?entry=ttu" className="footer-link"><MdLocationOn /> Cordillera 331, Modulo C1, Quilicura-Santiago de Chile</a></li>
              </ul>
              <br />


            </div>

          </Col>

          <Col sm={12} lg={4}>

            <div className='footer-column-div'>
              <b>{(t('About HANSA‑FLEX'))}</b>
              <ul>
                <li><a href="https://www.hansa-flex.de/en/magazine/" className="footer-link">{(t('Hydraulic Press'))}</a></li>
                <li><Link to="/certificates" className="footer-link">{(t('ISO 9001:2015 Certificate'))}</Link></li>
                <li><a href="https://www.hansa-flex.de/en/complaints-procedure-8-lksg/" className="footer-link">{(t('German Supply Chain Due Diligence Act'))}</a></li>
                <li><a href={accessibility} className="footer-link">{(t('Accessibility Policy'))}</a></li>

                {/* Uncomment the line below if needed */}
                {/* <li><Link to="/careers" className="footer-link">Careers</Link></li> */}
              </ul>
              <br />
            </div>
          </Col>


          {/* <Col xs={4} lg={3}>
          <div className='footer-column-div'>
          <h>Service</h>
          <ul>
                <li>Mobile rapid hydraulic service</li>
                <li>Fluid management</li>
                <li>Assembly and installation</li>
                <li>Hydarulic cylinder repair</li>
                <li>Preventive maintenance</li>
            </ul>
          </div>
          </Col> */}

          <Col sm={12} lg={4}>
            <div className='footer-column-div'>
              <b>{(t('Popular Categories'))}</b>
              <ul>
                <li><Link to="/hoses-and-hose-lines" className="footer-link">{(t('Products'))}</Link></li>
                <a href="https://my.hansa-flex.cl/en_GB/login" target="_blank" rel="noopener noreferrer" className="footer-link">{(t('X-CODE Manager'))}</a>


              </ul>
              <br />
            </div>
          </Col>

        </Row>

        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <b>{(t('Follow us'))}</b>

          </Col>
        </Row>


        <Row className="custom-3row ">

          {/*<Col><div className="circle-col"><img src={Imgkaggle} alt="" /></div></Col>*/}
          {/* <Col><div className="circle-col"><a href="https://www.kununu.com/de/hansa-flex" target="_blank" rel="noopener noreferrer"><img src={Imgkaggle} alt="" /></a></div></Col> */}
          {/*<Col><div className="circle-col"><img src={Imgxing} alt="" /></div></Col>*/}
          {/* <Col><div className="circle-col"><a href="https://www.xing.com/pages/hansa-flexag" target="_blank" rel="noopener noreferrer"><img src={Imgxing} alt="" /></a></div></Col> */}

          <Col><div className="circle-col"><a href="https://www.linkedin.com/company/hansa-flex-chile-s.a./" target="_blank" rel="noopener noreferrer"><div><img src={Imglinkidin} alt="" /></div></a></div></Col>
          <Col><div className="circle-col"><a href="https://www.youtube.com/@HANSAFLEXAG" target="_blank" rel="noopener noreferrer"><div><img src={Imgyoutube} alt="" /></div></a></div></Col>
          <Col><div className="circle-col"><a href="https://www.facebook.com/pages/Hansa%20flex%20chile/106745467386343/" target="_blank" rel="noopener noreferrer"><div><img src={Imgfacebook} alt="" /></div></a></div></Col>
        </Row>

        <Row className="line-row">
          <Col className="line-col"></Col>
        </Row>

        <div className='HfFooter-link-div'>
          <a className="hf-footer-link" href="">© {(t('Copyright All Rights Reserved'))}</a><span>  |  </span>
          <Link to="/hansa-flex-india-privacy-policy" className="footer-link">{(t('Privacy Policy'))}</Link>


          {/*<a className="hf-footer-link" href="">GT&C</a><span>  |  </span>
          <a className="hf-footer-link" href="">Purchasing and logistics</a><span>  |  </span>
        <a className="hf-footer-link" href="">Data protection</a>*/}

        </div>



      </Container>
    </footer >

  );
};

export default HfFooter;
